@import './css/general.css';

/********************************* HOME PAGE *************************************/

/*************************** Hero Section ***********************/

.hero-section {
  background-color: var(--ydb-white);
  margin-top: 115px;
}

.serif-heading {
  max-width: 25ch;
}

.hero-text {
  max-width: 60ch;
}

/** Marley Image **/

.hero-bizcard-marley {
  max-height: 400px;
}

/******************** Basic Obedience & Behavorial Commands *************/

.commands-1, .commands-2, .commands-3, .commands-4 {
  display: flex;
}

.daily-life {
  background-color: var(--ydb-maroon);

}

.daily-life img {
  background-color: var(--ydb-white);
  box-shadow: 0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.3);
}

.obedience-list {
  margin-top: -15px;
}

.bullet {
  display: block;
}

.training-button,  .training-button:hover {
  box-shadow: none;
}

.list-group-item {
  line-height: 110%;
}

/*************** MEET CLIENTS ***************/

.owner-quote {
  font-size: 17px;
}

.taking-break-text {
  max-width: 500px;
  margin: auto;
}

/*************** TESTIMONIALS ***************/

h2, h4 {
  color: var(--ydb-maroon);
}

.testimonial-cards {
  display: flex;
  width: 100%;
}

.testimonial {
  flex: 1;
  padding: 15px 25px;
  background-color: white;
  margin: 35px 15px 25px;
}

/*************** CONSIDERING TRAINING ***************/

/* #considering-training {
  background-color: var(--ydb-white);
} */

/*****************  MEDIA *************************/

/** Tablets & smaller **/

@media (max-width: 991.98px) {

  .serif-heading {
    margin-top: -15px;
  }

  .hero-image {
    order: -1;
    margin: auto;
    display: block;
  }

  .daily-life img {
    margin: auto;
    display: block;
  }

  .list-group-item {
    line-height: 100%;
  }

  .testimonial-cards {
    display: block;
  }

  .testimonial {
    margin-top: 50px;
  }

}
