@import '../css/general.css';

/*****************  TOP NAVIGATION *************************/

/* NavBar YDB Logo */

.ydb-navbar-logo {
  max-width: 350px;
}

/* NavBar Items */

.navbar-items li a:link, .navbar-items li a:visited,
.navbar-items li a:hover, .navbar-items li a:active {
  text-decoration: none;
}

/* NavBar Toggler */

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
  margin-right: 10px;
}

.navbar-toggler, .navbar-toggler:focus,
.navbar-toggler:active, .navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--ydb-white);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* Create animated X to close navbar */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0)
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* Create animated hamburger lines */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100)
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

/************************* Considering Training *********************/

#considering-training {
  background-color: var(--ydb-white);
}

.considering-text {
  line-height: 1.15;
  font-size: 2.5rem;
}

.free-eval {
  margin-top: -15px;
}

.hr1 {
  display: none;
}

/***************** FOOTER *************************/

.footer {
  background-color: var(--ydb-maroon) !important;
  margin-top: auto;
  color: var(--ydb-white);
}

.footer-logo-img {
  max-width: 300px;
}

.social-media-footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-400);
  padding: 0;
}

.social-media-footer li {
  list-style-type: none;
}

.copyright {
  padding-top: 0px;
  padding-bottom: 10px;
  font-weight: 550;
}

/*****************  MEDIA *************************/

@media (max-width: 991.98px) {

  .ydb-navbar-logo {
    max-width: 275px;
  }

  .hr1 {
    display: hidden;
  }

  .footer-logo-img {
    max-width: 275px;
  }

}

@media (max-width: 767.98px) {

  .hr1 {
    display: block;
  }

}

@media (max-width: 290px) {

  .ydb-navbar-logo {
    max-width: 200px;
  }

  .considering-text {
    line-height: 1.15;
    font-size: 1.75rem;
  }

  .footer-logo-img {
    max-width: 210px;
  }

}
